import { documentoStore } from './../../store/modules/documentos/index';
import { defineComponent, reactive } from 'vue';
import { DxForm, DxSimpleItem, DxButtonItem, DxButtonOptions, DxGroupItem, DxRequiredRule } from 'devextreme-vue/ui/form';
import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';

export default defineComponent({
  name: 'Consultar Monofásicos',
  setup() {
    const docStore = documentoStore();
    const state = reactive({
      ncm: '',
      valueMonofasico: '',
      valueAliquotaZero: '',
      isLoading: false,
      valuePisFabricante: '',
      valuePisAtacado: '',
      valuePisVarejo: '',
      valueCofinsFabricante: '',
      valueCofinsAtacado: '',
      valueCofinsVarejo: ''
    });

    const searchButtonProperties = {
      text: 'Pesquisar',
      type: 'default',
      stylingMode: 'contained',
      icon: 'mdi mdi-magnify',
      useSubmitBehavior: true
    }

    async function Search() {
      docStore.$reset();
      state.isLoading = true;
      state.valueAliquotaZero = '';
      state.valueMonofasico = ';'
      try {
        await docStore.GET_MONOFASICO_BY_NCM(state.ncm);
        state.isLoading = false;

        state.valueAliquotaZero = docStore.DocMonofasico.aliquotaZero == true ? 'Sim' : 'Não';
        state.valueMonofasico = docStore.DocMonofasico.monofasicoSt == true ? 'Sim' : 'Não';
        state.valuePisFabricante = docStore.DocMonofasico.aliquotaPisFabricante + '%';
        state.valuePisAtacado = docStore.DocMonofasico.aliquotaPisAtacado + '%';
        state.valuePisVarejo = docStore.DocMonofasico.aliquotaPisVarejo + '%';
        state.valueCofinsFabricante = docStore.DocMonofasico.aliquotaCofinsFabricante + '%';
        state.valueCofinsAtacado = docStore.DocMonofasico.aliquotaCofinsAtacado + '%';
        state.valueCofinsVarejo = docStore.DocMonofasico.aliquotaCofinsVarejo + '%';
      } catch (error) {
        return error;
      }
    }

    return {
      searchButtonProperties,
      state,
      Search,
      docStore
    }
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DxGroupItem,
    DxRequiredRule,
    DxLoadPanel
  }
})