<template>
  <div id="main-monofasico">
    <p class="title">Monofásicos</p>

    <div class="dx-card p-3">
      <form @submit.prevent="Search">
        <DxForm
          :show-colon-after-label="true"
          :col-count="2"
          :form-data="state"
          width="400px"
        >
          <DxSimpleItem
            data-field="ncm"
            :label="{ text: 'Informe o NCM' }"
            :editor-options="{ mask: '0000.00.00' }"
          >
            <DxRequiredRule />
          </DxSimpleItem>
          <DxButtonItem
            :button-options="searchButtonProperties"
            vertical-alignment="center"
            css-class="mt-4"
          />
        </DxForm>
      </form>
    </div>

    <DxForm
      :form-data="docStore.DocMonofasico"
      :readOnly="true"
      :element-attr="{ class: 'dx-card form' }"
    >
      <DxSimpleItem item-type="group" :col-count="6" css-class="my-3">
        <DxSimpleItem
          data-field="grupoBeneficiado"
          :label="{ text: 'Grupo Beneficiado' }"
          :col-span="2"
          :editor-options="{ stylingMode: 'underlined' }"
        />
        <DxSimpleItem
          data-field=""
          editor-type="dxTextBox"
          :label="{ text: 'Monofásico' }"
          :editor-options="{
            stylingMode: 'underlined',
            value: state.valueMonofasico
          }"
        />        
        <DxSimpleItem
          data-field=""
          editor-type="dxTextBox"
          :label="{ text: 'Alíquota Zero' }"
          :editor-options="{
            stylingMode: 'underlined',
            value: state.valueAliquotaZero
          }"
        />
        <DxSimpleItem
          data-field="inicioVigencia"
          :label="{ text: 'Início Vigência' }"
          editor-type="dxDateBox"
          :editor-options="{ stylingMode: 'underlined' }"
        />
        <DxSimpleItem
          data-field="finalVigencia"
          :label="{ text: 'Final Vigência' }"
          editor-type="dxDateBox"
          :editor-options="{ stylingMode: 'underlined' }"
        />
      </DxSimpleItem>

      <DxSimpleItem item-type="group" :col-count="3" css-class="my-3">
        <DxSimpleItem
          item-type="group"
          :col-count="3"
          caption="Fabricantes/Importadores"
        >
          <DxSimpleItem
            data-field="cstFabImp"
            :label="{ text: 'CST' }"
            :editor-options="{ stylingMode: 'underlined' }"
          />
          <DxSimpleItem
            data-field=""
            :label="{ text: 'PIS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valuePisFabricante }"
          />
          <DxSimpleItem
            data-field="aliquotaCofinsFabricante"
            :label="{ text: 'COFINS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valueCofinsFabricante }"
          />
        </DxSimpleItem>

        <DxSimpleItem item-type="group" :col-count="3" caption="Atacado">
          <DxSimpleItem
            data-field="cstAtacado"
            :label="{ text: 'CST' }"
            :editor-options="{ stylingMode: 'underlined' }"
          />
          <DxSimpleItem
            data-field=""
            :label="{ text: 'PIS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valuePisAtacado }"
          />
          <DxSimpleItem
            data-field="aliquotaCofinsAtacado"
            :label="{ text: 'COFINS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valueCofinsAtacado }"
          />
        </DxSimpleItem>

        <DxSimpleItem item-type="group" :col-count="3" caption="Varejo">
          <DxSimpleItem
            data-field="cstVarejo"
            :label="{ text: 'CST' }"
            :editor-options="{ stylingMode: 'underlined' }"
          />
          <DxSimpleItem
            data-field=""
            :label="{ text: 'PIS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valuePisVarejo }"
          />
          <DxSimpleItem
            data-field="aliquotaCofinsVarejo"
            :label="{ text: 'COFINS' }"
            :editor-options="{ stylingMode: 'underlined', value: state.valueCofinsVarejo }"
          />
        </DxSimpleItem>
      </DxSimpleItem>

      <DxSimpleItem item-type="group" :col-count="1" css-class="my-3">
        <DxSimpleItem
          data-field="observacoes"
          :label="{ text: 'Observações' }"
          :editor-options="{ stylingMode: 'underlined' }"
        />
        <DxSimpleItem
          data-field="descricao1Ex"
          :label="{ text: 'Descrição EX' }"
          :editor-options="{ stylingMode: 'underlined' }"
        />
        <DxSimpleItem
          data-field="descricao2Ex"
          :label="{ text: 'Descrição EX' }"
          :editor-options="{ stylingMode: 'underlined' }"
        />
        <DxSimpleItem
          data-field="descricao3Ex"
          :label="{ text: 'Descrição EX' }"
          :editor-options="{ stylingMode: 'underlined' }"
        />
      </DxSimpleItem>
    </DxForm>

    <DxLoadPanel
      :visible="state.isLoading"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(255,255,255, 0.8)"
    />
  </div>
</template>
<script src="./index.ts"></script>

<style lang="postcss" scoped>
.form {
  @apply p-3 mt-4;
}
</style>